<template>
  <div class="inner-section">
    <card>
      <!-- search section start -->
      <template v-slot:searchHeaderTitle>
        <h4 class="card-title">{{ $t('configuration.resource_pool') }}</h4>
      </template>
      <template v-slot:searchBody>
        <b-row>
          <b-col xs="12" sm="12" md="4" lg="4" xl="4">
            <b-form-group
              label-for="org_id"
            >
              <template v-slot:label>
                {{ $t('configuration.area_of_interest') }}
              </template>
              <b-form-select
                plain
                id="org_id"
                :options="areaOfInterest"
                v-model="search.area_of_interest_id"
              >
                <template v-slot:first>
                  <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                </template>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col xs="12" sm="12" md="4" lg="4" xl="4">
            <b-form-group
              label-for="name"
            >
              <template v-slot:label>
                {{ $t('configuration.search') }} {{ $t('configuration.name') }}
              </template>
              <b-form-input
                  id="name"
                  type="text"
                  v-model="search.name"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col sm="4">
            <b-button size="sm" variant="primary" class="mt-20" @click="searchData">
              <i class="ri-search-line"></i> {{ $t('globalTrans.search') }}
            </b-button>
          </b-col>
        </b-row>
      </template>
      <!-- search section end -->
    </card>

    <body-card>
      <!-- table section start -->
      <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t('configuration.resource_pool_s') }} {{ $t('globalTrans.list') }}</h4>
      </template>
      <template v-slot:headerAction>
        <a href="javascript:" class="btn-add" @click="resetId" v-b-modal.modal-form><i class="ri-add-fill"></i> {{ $t('globalTrans.add_new') }}</a>
      </template>
      <template v-slot:body>
        <b-overlay :show="loadingState">
          <!-- <base-table v-on:editMethodInParent="editMethodInParent" :labelData="labelData" :columns="columns" :search="search" :searchData="searchData" :paginations="pagination" :actions="actions" :imageUrl ="bftiResReportServiceBaseUrl" /> -->
          <b-table :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" head-variant="primary" class="tg" :items="listData" :emptyText="$t('globalTrans.noDataFound')" show-empty bordered hover :fields="columns">
                  <template v-slot:cell(serial)="data">
                    {{ $n(data.item.serial + pagination.slOffset) }}
                  </template>
                  <template v-slot:cell(status)="data">
                    <span class="badge badge-success" v-if="data.item.status == 1">{{$t('globalTrans.active')}}</span>
                    <span class="badge badge-warning"  v-if="data.item.status == 2">{{$t('globalTrans.inactive')}}</span>
                    <span class="badge badge-danger"  v-if="data.item.status == 3">{{$t('globalTrans.reject')}}</span>
                  </template>
                  <template v-slot:cell(action)="data">
                    <b-button v-if="data.item.source === 1 && data.item.status === 2" v-b-modal.modal-2 variant=" iq-bg-warning mr-1" size="sm" @click="addRemarks(data.item.id, 3)" class="action-btn btn btn-danger" :title="$t('globalTrans.rejected')"><i class="ri-close-line"></i></b-button>
                    <b-button v-if="data.item.source === 1 && data.item.status === 2" v-b-modal.modal-2 variant=" iq-bg-info mr-1" size="sm" @click="addRemarks(data.item.id, 1)" class="action-btn approve" :title="$t('globalTrans.approved')"><i class="ri-check-line"></i></b-button>
                    <b-button v-if="data.item.status === 1 || data.item.source === 1" v-b-modal.modal-form variant=" iq-bg-success mr-1" size="sm" @click="edit(data.item)" class="action-btn edit" :title="$t('globalTrans.edit')"><i class="ri-pencil-fill"></i></b-button>
                    <b-button :variant="data.item.status === 2 ? ' btn-danger' : ' btn-success'" size="sm" class="action-btn status" :title="data.item.status === 2 ? $t('globalTrans.inactive') : $t('globalTrans.active')" @click="remove(data.item)">
                      <i :class="data.item.status === 2  ? 'ri-toggle-line' : 'ri-toggle-fill'"></i>
                    </b-button>
                  </template>
                </b-table>
        </b-overlay>
      </template>
      <!-- table section end -->
    </body-card>
    <b-modal id="details" size="lg" :title="$t('configuration.resource_pool') + ' ' + $t('globalTrans.details')" hide-footer :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <Details :id="detailItemId" :key="detailItemId"/>
    </b-modal>
    <b-modal id="modal-form" size="lg" :title="formTitle" hide-footer :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <Form :id="editItemId" :key="editItemId"/>
    </b-modal>
    <b-modal id="modal-2" size="lg" :title="remarksData.status === 3 ? $t('globalTrans.reject') : $t('globalTrans.approve')" hide-footer ok-only ok-variant="danger">
      <b-container fluid>
        <b-row>
            <b-col lg="12" sm="12">
                <iq-card>
                    <ValidationObserver ref="form3" v-slot="{ handleSubmit, reset }">
                        <b-form @submit.prevent="handleSubmit(saveData)" @reset.prevent="reset">
                            <b-row class="pt-3 px-2">
                              <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                                  <ValidationProvider name="Remarks" vid="remarks" rules="required">
                                      <b-form-group
                                          class="row"
                                          label-cols-sm="2"
                                          label-for="remarks"
                                          slot-scope="{ valid, errors }"
                                      >
                                      <template v-slot:label>
                                          {{$t('globalTrans.remarks')}} {{$t('globalTrans.en')}} <span class="text-danger">*</span>
                                      </template>
                                      <b-textarea
                                          id="remarks"
                                          v-model="remarksData.remarks"
                                          :state="errors[0] ? false : (valid ? true : null)"
                                          ></b-textarea>
                                          <div class="invalid-feedback">
                                          {{ errors[0] }}
                                          </div>
                                      </b-form-group>
                                  </ValidationProvider>
                              </b-col>
                              <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                                  <ValidationProvider name="Remarks (Bn)" vid="remarks_bn" rules="required">
                                      <b-form-group
                                          class="row"
                                          label-cols-sm="2"
                                          label-for="remarks_bn"
                                          slot-scope="{ valid, errors }"
                                      >
                                      <template v-slot:label>
                                          {{$t('globalTrans.remarks')}} {{$t('globalTrans.bn')}} <span class="text-danger">*</span>
                                      </template>
                                      <b-textarea
                                          id="remarks_bn"
                                          v-model="remarksData.remarks_bn"
                                          :state="errors[0] ? false : (valid ? true : null)"
                                          ></b-textarea>
                                          <div class="invalid-feedback">
                                          {{ errors[0] }}
                                          </div>
                                      </b-form-group>
                                  </ValidationProvider>
                              </b-col>
                            </b-row>
                            <div class="row pb-3 px-2">
                                <div class="col-sm-3"></div>
                                <div class="col text-right">
                                    <b-button type="submit" variant="primary" class="mr-2">{{ $t('globalTrans.submit') }}</b-button>
                                    &nbsp;
                                    <b-button variant="danger" @click="$bvModal.hide('modal-2')">{{ $t('globalTrans.cancel') }}</b-button>
                                </div>
                            </div>
                        </b-form>
                    </ValidationObserver>
                </iq-card>
          </b-col>
        </b-row>
      </b-container>
    </b-modal>
  </div>
</template>
<script>
import Form from './Form.vue'
import RestApi, { bftiResReportServiceBaseUrl } from '@/config/api_config'
import { resourcePoolListApi, resourcePoolToggleApi } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/list'
import Details from './Details'

export default {
  mixins: [ModalBaseMasterList],
  components: {
    Form, Details
  },
  data () {
    return {
      bftiResReportServiceBaseUrl: bftiResReportServiceBaseUrl,
      sortBy: '',
      search: {
        name: '',
        area_of_interest_id: 0,
        limit: 20
      },
      item: {},
      labelData: [
        { labels: 'globalTrans.sl_no', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 1, thStyle: { width: '8%' } },
        { labels: 'configuration.area_of_interest', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 2, thStyle: { width: '15%' } },
        { labels: 'configuration.name', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 3, thStyle: { width: '25%' } },
        { labels: 'configuration.designation', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 4, thStyle: { width: '25%' } },
        { labels: 'configuration.contact_no', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 6, thStyle: { width: '12%' } },
        { labels: 'globalTrans.status', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 7 },
        { labels: 'globalTrans.action', class: 'text-left', show: '1', order: 8, thStyle: { width: '15%' } }
      ],
      actions: {
        edit: true,
        details: true,
        toogle: true,
        delete: false
      },
      remarksData: {
          id: '',
          remarks: '',
          remarks_bn: '',
          status: 0
      }
    }
  },
  created () {
    this.loadData()
  },
  watch: {
    'search.limit': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.loadData()
      }
    }
  },
  computed: {
    columns () {
      const labelData = this.labelData
      const labels = labelData.map((item, index) => {
        return Object.assign(item, { label: this.$t(item.labels, this.$i18n.locale) })
      })
      let keys = []
      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'serial' },
          { key: 'area_of_interests_bn' },
          { key: 'name_bn' },
          { key: 'designation_bn' },
          { key: 'contact_no' },
          { key: 'status' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'serial' },
          { key: 'area_of_interests_en' },
          { key: 'name_en' },
          { key: 'designation' },
          { key: 'contact_no' },
          { key: 'status' },
          { key: 'action' }
        ]
      }
      return labels.map((item, index) => {
        return Object.assign(item, keys[index])
      })
    },
    formTitle () {
      return (this.editItemId === 0) ? this.$t('configuration.resource_pool') + ' ' + this.$t('globalTrans.entry') : this.$t('configuration.resource_pool') + ' ' + this.$t('globalTrans.modify')
    },
    resourcePoolList () {
      return this.$store.state.configurationService.commonObj.resourcePoolList.filter(item => item.status === 1)
    },
    areaOfInterest () {
      return this.$store.state.BftiResearchAndReportService.commonObj.areaOfInterestList.filter(item => item.status === 1)
    }
  },
  methods: {
    remove (item) {
      this.changeStatus(6, bftiResReportServiceBaseUrl, resourcePoolToggleApi, item, 'bfti-research-and-report-service')
    },
    addRemarks (id, status) {
      this.remarksData.id = id
      this.remarksData.status = status
    },
    editMethodInParent (item, type) {
      if (type === 1) {
        this.edit(item)
      } else if (type === 2) {
        this.changeStatus(6, bftiResReportServiceBaseUrl, resourcePoolToggleApi, item)
      } else if (type === 3) {
        this.details(item)
      }
    },
    searchData () {
      this.loadData()
    },
    async loadData () {
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      const result = await RestApi.getData(bftiResReportServiceBaseUrl, resourcePoolListApi, params)
      if (result.success) {
        const listData = result.data.data.map((item, index) => {
          const orgObj = this.$store.state.BftiResearchAndReportService.commonObj.areaOfInterestList.find(law => law.value === parseInt(item.area_of_interest_id))
          const orgData = {}
            if (typeof orgObj !== 'undefined') {
                orgData.area_of_interests_en = orgObj.text_en
                orgData.area_of_interests_bn = orgObj.text_bn
            } else {
              orgData.org_name = ''
              orgData.org_name_bn = ''
            }
          return Object.assign({}, item, { serial: index }, orgData, { pre_left_logo: item.left_logo }, { pre_right_logo: item.right_logo })
        })
        this.$store.dispatch('setList', listData)
        this.paginationData(result.data, this.search.limit)
      } else {
        this.$store.dispatch('setList', [])
        this.paginationData([])
      }
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    },
    saveData () {
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      const loadingState = { loading: false, listReload: false }
      RestApi.postData(bftiResReportServiceBaseUrl, '/configuration/resource-pool/approve', this.remarksData)
      .then(response => {
        if (response.success) {
        loadingState.listReload = true
        this.$store.dispatch('mutateCommonProperties', loadingState)
        this.$store.dispatch('CommonService/mutateCommonObj', { hasDropdownLoaded: false })
          window.vm.$toast.success({
            title: 'Success',
            message: 'Data Updated Successfully',
            color: '#D6E09B'
          })
          this.$bvModal.hide('modal-2')
          this.loadData()
        } else {
          window.vm.$toast.error({
            title: 'Error',
            message: 'Operation failed! Please, try again.'
          })
        }
      })
    }
  }
}
</script>
